// gatsby-browser.js
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "./src/global.css"

// Highlighting for code blocks
import "prismjs/themes/prism-coy.css"

// FontAwesome
import "@fortawesome/fontawesome-svg-core/styles.css"

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath })
    }
  }, 100)
}
